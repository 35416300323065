@import "../../Assets/styles/constant.scss";
@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

.center {
    @include flex-column-center;
    min-height: 100vh;
    .login-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: $nav-height;
        background-color: $kaki;
        font-family: "AbrilText";
        max-width: 320px;
        margin: 10px;
        padding: 10px;
    }

    #alert {
        @include fixed-message;
    }
}
