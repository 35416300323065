@import "../../Assets/styles/constant.scss";
@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

@mixin background {
    font-family: "AbrilText";
    font-size: 16px;
    font-weight: 400;
    padding: 5px 10px;
}

.loading {
    @include flex-column-center;
    width: 100%;
    min-height: 50vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $nav-height;
    box-shadow: none;

    .background {
        &-head {
            @include background();
            background-color: $kaki;
            color: $white;
        }

        &-body {
            @include background();
            background-color: white;
            color: $kaki;
        }
    }

    h1 {
        color: $kaki;
        font-family: AbrilText;
        font-weight: 600;
        padding-top: 40px;
        font-size: 30px;
        line-height: 30px;
    }

    .top-header {
        display: flex;
        width: 90%;
        justify-content: space-around;
        margin: 20px 0px;
    }
    .container {
        max-width: 90%;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px $lightgreen;
        height: 100%;
    }

    .group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
    }
}
