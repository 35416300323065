body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@mixin font-face($font-family, $font-style, $font-weight, $src) {
    @font-face {
        font-family: $font-family;
        font-style: $font-style;
        font-weight: $font-weight;
        src: $src;
    }
}

@include font-face(
    AbrilDisplay,
    normal,
    400,
    url("./Assets/fonts/Abril_Display/Abril_Display_Regular.otf")
);
@include font-face(
    AbrilDisplay,
    italic,
    400,
    url("./Assets/fonts/Abril_Display/Abril_Display_Italic.otf")
);
@include font-face(
    AbrilDisplay,
    normal,
    600,
    url("./Assets/fonts/Abril_Display/Abril_Display_SemiBold.otf")
);
@include font-face(
    AbrilDisplay,
    italic,
    600,
    url("./Assets/fonts/Abril_Display/Abril_Display_BoldItalic.otf")
);
@include font-face(
    AbrilDisplay,
    normal,
    800,
    url("./Assets/fonts/Abril_Display/Abril_Display_ExtraBold.otf")
);
@include font-face(
    AbrilDisplay,
    italic,
    800,
    url("./Assets/fonts/Abril_Display/Abril_Display_ExtraBoldItalic.otf")
);
@include font-face(AbrilText, normal, 400, url("./Assets/fonts/Abril_Text/Abril_Text_Regular.otf"));
@include font-face(AbrilText, italic, 400, url("./Assets/fonts/Abril_Text/Abril_Text_Italic.otf"));
@include font-face(
    AbrilText,
    normal,
    600,
    url("./Assets/fonts/Abril_Text/Abril_Text_SemiBold.otf")
);
@include font-face(
    AbrilText,
    italic,
    600,
    url("./Assets/fonts/Abril_Text/Abril_Text_SemiBoldItalic.otf")
);
@include font-face(AbrilText, normal, 700, url("./Assets/fonts/Abril_Text/Abril_Text_Bold.otf"));
@include font-face(
    AbrilText,
    italic,
    700,
    url("./Assets/fonts/Abril_Text/Abril_Text_BoldItalic.otf")
);
@include font-face(
    AbrilText,
    normal,
    800,
    url("./Assets/fonts/Abril_Text/Abril_Text_ExtraBold.otf")
);
@include font-face(
    AbrilText,
    italic,
    800,
    url("./Assets/fonts/Abril_Text/Abril_Text_ExtraBoldItalic.otf")
);
