@import "../../Assets/styles/colors.scss";

.main {
    position: relative;
    background: none;
    border: none;
    outline: none;
    padding: 5px;
    width: 30px;
    height: 100%;

    .alert {
        position: absolute;
        z-index: 10001;
        transform: translate(0px, -50%);
        left: 200%;
        top: 50%;
        width: 300px;
    }

    .active {
        color: red;
        font-size: 30px;
    }

    .inactive {
        color: $orange;
        font-size: 30px;
    }
}
