@import "./colors.scss";

@mixin full-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    z-index: 10002;
}

@mixin flex-column-center {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin simple-font-abril($color, $size) {
    font-family: "AbrilText";
    font-size: $size;
    font-weight: 500;
    color: $color;
    text-align: center;
}

@mixin fixed-message {
    @include simple-font-abril($white, 20px);
    position: fixed;
    transform: translate(50%, 0);
    max-width: 90%;
    top: 40px;
    left: 50%;
}

@mixin rotate {
    animation-name: spin;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@mixin no-style {
    box-shadow: none;
    outline: none;
    border: none;
    background: none;
}
