@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/screenSizes.scss";
@import "../../Assets/styles/constant.scss";

.main {
    height: $nav-height;
    font-size: 18px;
    top: 0;
    left: 0;
    right: 0;
    position: fixed !important;
    z-index: 10000;
    padding: 0;

    @media (max-width: $max-screen-landscape) {
        font-size: 18px;
    }

    a {
        outline: none;
    }
}

.background {
    background-color: $white;

    .nav {
        background-color: $white;
        justify-content: flex-end;
    }
}

.color {
    color: $black !important;
    border-color: $black !important;
    padding: 5px 15px;
    margin-right: 20px;

    &:hover {
        cursor: pointer;
    }
}

.logo {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    height: inherit;
    padding: 5px;
    text-align: center;

    img {
        width: auto;
        height: 45px;
        margin-right: 10px;
        margin-left: 20px;
    }
}
