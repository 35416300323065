@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
        @include simple-font-abril($lightgreen, 16px);
        text-align: left;
        border-radius: 5px;
        border: 1px solid $orange;
        padding: 5px 10px;
        width: 200px;
        color: $kaki;
        font-size: 16px;
        height: 42px;

        &:focus {
            outline: none;
            border-color: $kaki;
        }
    }
}
