@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

@mixin no-style {
    box-shadow: none;
    outline: none;
    border: none;
    background: none;
}

.simple-wrapper {
    @include flex-column-center;
    margin: 5px 10px;
    padding: 2px;
    background-color: $orange;
    transition: 0.3s;

    &:hover {
        background-color: $kaki;
    }

    .simple-button {
        @include simple-font-abril($white, 20px);
        @include no-style;
        border: solid 2px $white;
        padding: 5px 10px;
        background-color: $orange;
        transition: 0.3s;

        &:hover {
            background-color: $kaki;
        }
    }
}

.navigation-wrapper {
    @include no-style;
    text-decoration: none;
    color: inherit;

    &:focus {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.mutable-button {
    @include no-style;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    min-width: 150px;

    &.bg1 {
        @include simple-font-abril($white, 16px);
        background-color: $black;
    }

    &.bg2 {
        @include simple-font-abril($black, 16px);
        background-color: $white;
    }

    &.bg3 {
        @include simple-font-abril($white, 16px);
        background-color: #800000;
    }
}
