@import "../../Assets/styles/mixins.scss";
@import "../../Assets/styles/colors.scss";

.main {
    width: 100px;
    height: 100px;
    background-color: $kaki;
    margin: 10px 0px;
    margin-right: 10px;
    border-radius: 5px;
    position: relative;

    .container {
        width: 100%;
        height: 100%;

        img {
            max-width: 60px;
            max-height: 60px;
            margin-bottom: 0px;
        }

        p {
            @include simple-font-abril($white, 14px);
            line-height: 30px;
            vertical-align: middle;
            text-align: center;
            padding: 2.5px 5px;
            font-weight: 400;
        }
    }
}
