@import "../../Assets/styles/constant.scss";
@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

.main {
    width: 100%;
    padding: 10%;
}

.loading {
    @include flex-column-center;
    width: 100%;
    min-height: 50vh;
}
