@import "../../Assets/styles/mixins.scss";

button {
    &.main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $lightgreen !important;
        background-color: $lightgreen !important;
        margin: 10px;
        height: 130px;
        width: 120px;
        position: relative;

        .picto_1 {
            position: absolute;
            top: 0px;
            right: 0px;
        }
        .picto_2 {
            position: absolute;
            top: 0px;
            right: 25px;
        }

        p {
            @include simple-font-abril($white, 16px);
            padding: 5px;
            line-height: 16px;
            margin: 0px;
            margin-top: 10px;
        }

        img {
            width: 60px;
            margin: 0px;
        }
    }
}
