@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

@mixin no-style {
    box-shadow: none;
    outline: none;
    border: none;
    text-align: left;
}

.simple-wrapper {
    @include flex-column-center;
    padding: 2px;

    label {
        @include simple-font-abril($white, 20px);
    }

    .simple-input {
        @include simple-font-abril($lightgreen, 16px);
        @include no-style;
        padding: 5px 10px;
        transition: 0.3s;

        &:focus {
            border: solid 1px $kaki;
        }
    }
}
