@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

.main {
    user-select: none;
    background-color: $kaki;
    border-radius: 5px;
    margin: 5px;
    padding: 5px 10px;
    color: $kaki;

    &:hover {
        background-color: $orange;
        cursor: pointer;
        transition: 0.5s;
    }

    span {
        @include simple-font-abril($white, 16px);
        padding: 0px;
    }
}
