@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

.main {
    width: 100%;

    .alert {
        position: fixed;
        z-index: 10001;
        transform: translate(-50%, 50%);
        left: 50%;
        top: 80px;
        width: 300px;
        min-height: 30px;
    }

    .comment {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0px;

        span {
            @include simple-font-abril($kaki, 18px);
            line-height: 18px;
            margin: 0px 10px;
            padding: 10px 0px;
            white-space: nowrap;
            text-align: left;
            width: 100%;
            font-weight: 600;
        }

        textarea {
            height: 150px;
            width: 100%;
            background-color: $grey;
            border: none;
            @include simple-font-abril($black, 20px);
            text-align: left;
            vertical-align: top;
            padding: 10px;
            margin-bottom: 10px;
        }
    }
}
