@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/mixins.scss";

$font-size: 16px;

@mixin layout {
    margin: 0px;
    padding: 0px 5px;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.rotate {
    @include rotate;
}

.main {
    width: 300px;
    margin: 10px auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    color: $white;
    font-size: $font-size;
    min-height: 45px;
    transition: 0.5s;

    &.green {
        background-color: $lightgreen;
    }

    &.orange {
        background-color: $orange;
    }

    &:hover {
        background-color: $orange;
    }

    .title {
        @include layout;
        @include flex-column-center;
        width: 200px;
        white-space: pre-wrap;
        line-height: $font-size;
        align-items: flex-start;

        span {
            padding: 0px 5px;
            line-height: 25px;
            text-align: left;
        }
    }

    .quantity {
        @include layout;
        font-weight: 600;
        width: 30px;
        font-size: 24px;

        line-height: $font-size;
    }

    &:hover {
        cursor: pointer;
    }

    button {
        background-color: inherit;
        display: flex;
        color: $white;
        background: none;
        outline: none;
        border: none;
        width: 32.5px;
        @include layout;
    }
}

.full-background {
    @include full-background;
}
.box {
    @include flex-column-center;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-width: 80%;
    width: 1000px;
    height: auto;
    padding: 20px;
    background-color: $white;
    color: $kaki;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px $kaki;
    z-index: 10002;

    h2 {
        @include simple-font-abril($lightgreen, 26px);
    }

    img {
        max-width: 200px;
        max-height: 200px;
        margin: 10px;
    }

    .inline {
        width: 90%;
        text-align: left;

        p {
            font-size: 16px;
            white-space: nowrap;
            margin: 0;
            padding: 5px;
            width: 100%;
            display: flex;
            span {
                text-align: right;
                color: $orange;
                width: 100%;
            }
        }
    }

    .list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;
    }
}

.production-item {
    @include flex-column-center;
    height: auto;
    width: 100%;
    border: 1px groove $orange;
    margin: 10px 0px;
    background-color: $white;

    section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 10px;

        img {
            max-width: 80px;
            max-height: 80px;
            margin-right: 20px;
        }

        h2 {
            @include simple-font-abril($kaki, 18px);
            line-height: 18px;
            margin: 0;
            margin-right: 20px;
            padding: 0px;
            white-space: nowrap;
            text-align: left;
            width: 100%;

            span {
                font-weight: 800;
            }
        }

        h3 {
            @include simple-font-abril($kaki, 16px);
            line-height: 18px;
            margin: 0px 10px;
            padding: 0px;
            white-space: nowrap;
            text-align: left;
            width: 100%;
            font-weight: 800;

            span {
                color: $orange;
                font-size: 25px;
                line-height: 25px;
                padding: 0px 10px;
            }
        }

        button {
            @include no-style;

            &:hover {
                color: $orange;
            }
        }
    }
}
