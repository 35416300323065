@import "../../Assets/styles/colors.scss";
@import "../../Assets/styles/screenSizes.scss";
@import "../../Assets/styles/mixins.scss";
@import "../../Assets/styles/constant.scss";

.main {
    @include flex-column-center;
    padding-top: $nav-height;
    padding-bottom: 50px;
    width: 100%;
    min-height: 100vh;
    height: auto;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1000px;
    width: 90%;
    padding-top: 20px;
}

.client-card {
    background-color: $lightgreen;
    padding: 10px;
    @include flex-column-center;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 2px $kaki;
    max-width: 450px;
    margin: 20px auto;

    img {
        width: 150px;
        box-shadow: 0px 0px 5px $orange;
        border-radius: 5px;
        margin-bottom: 5px;
    }

    h3 {
        @include simple-font-abril($white, 18px);
        padding: 15px 0;
        margin: 0;
    }

    p {
        @include simple-font-abril($white, 16px);
        padding: 5px 0;
        margin: 0;
        width: 100%;
        text-align: left;
    }
}

.accordion {
    width: 100%;
}

.message {
    width: 100%;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;

        p {
            @include simple-font-abril($black, 20px);
            text-align: left;
            font-weight: 1000;
        }
        button {
            @include no-style;

            &:hover {
                color: $orange;
            }
        }
    }

    form {
        width: 100%;

        textarea {
            height: 150px;
            width: 100%;
            background-color: $grey;
            border: none;
            @include simple-font-abril($black, 20px);
            text-align: left;
            vertical-align: top;
            padding: 10px;
            margin-bottom: 10px;
        }

        div {
            display: flex;
            justify-content: flex-start;
            padding-left: 0px;
        }
    }
}

.answers {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .answer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;

        h2 {
            @include simple-font-abril($black, 16px);
            padding-right: 10px;
            white-space: pre-wrap;
        }

        .choices {
            display: flex;
            flex-wrap: wrap;

            span {
                @include simple-font-abril($orange, 16px);
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 10px;
                text-align: left;
            }
        }
    }
}

.charts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .chart {
        width: 40%;
        min-width: 300px;
        margin: 20px auto;

        h2 {
            @include simple-font-abril($kaki, 18px);
            font-weight: 600;
            white-space: pre-wrap;
        }

        .progress-bar {
            height: 20px;
            width: 100%;
            background-color: $lightgreen;
            position: relative;

            .container {
                position: absolue;
                left: 0;
                top: 0;
                background-color: $orange;
            }

            .left {
                @include simple-font-abril($kaki, 12px);
                font-weight: 800;
                position: absolute;
                left: 0;
            }

            .right {
                @include simple-font-abril($kaki, 14px);
                font-weight: 800;
                position: absolute;
                right: 0;
            }
        }
    }
}
.no-style {
    @include no-style;
    align-self: flex-start;

    &:hover {
        color: $orange;
    }
    span {
        @include simple-font-abril(inherit, 20px);
    }
}
